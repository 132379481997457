<template src="./RecoveryAtIdError.html"></template>

<script>
export default {
  name: 'recovery-at-id-error',
  methods: {
    openUrl() {
      window.open('https://soporte.arrobamedellin.edu.co/support/upload/', '_blank');
    }
  }
}
</script>

<style lang="scss" scoped src="./RecoveryAtIdError.scss">

</style>